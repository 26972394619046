<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div id="background-container">
                <div class="level-item">
                    <h1 class="title is-1 page-title">COMPARE COVER LEVELS</h1>
                </div>
                <div class="">
                    <div class="columns" v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'">
                        <div class="column"></div>
                        <div class="column title-box">Platinum</div>
                        <div class="column title-box">Gold</div>
                        <div class="column title-box">Silver</div>
                    </div>
                    <div class="columns" v-else-if="$route.query.type === 'CR'">
                        <div class="column"></div>
                        <div class="column title-box">Cruise</div>
                    </div>
                    <div class="columns" v-else>
                        <div class="column"></div>
                        <div class="column title-box">Longstay</div>
                    </div>

                    <!-- Initial -->
                    <div class="columns" v-for="cover in initialCovers" :key="cover['cover_limit_id']">
                        <template v-if="(cover['longstay_limit'] !== 'N/A' && $route.query.type === 'LS') || $route.query.type !== 'LS'">
                            <div class="column name-box">
                                <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                                    <span v-html="cover['covername']"></span>
                                    <template v-slot:content>
                                        <div v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                                    </template>
                                </b-tooltip>
                                <span v-else v-html="cover['covername']"></span>
                            </div>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['platinum_limit'])"/>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['gold_limit'])"/>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['silver_limit'])"/>
                            <div v-if="$route.query.type === 'LS'" class="column cost-box" v-html="cost(cover['longstay_limit'])"/>
                            <div v-if="$route.query.type === 'CR'" class="column cost-box" v-html="cost(cover['cruise_limit'])"/>
                        </template>
                    </div>

                    <!-- Ski -->
                    <div class="columns" v-for="cover in JSON.parse(skiCovers)" :key="cover['cover_limit_id']">
                        <div class="column name-box">
                            <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                                <span v-html="cover['covername']"></span>
                                <template v-slot:content>
                                    <div v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                                </template>
                            </b-tooltip>
                            <span v-else v-html="cover['covername']"></span>
                        </div>
                        <div class="column cost-box" v-html="cost(cover['platinum_limit'])"></div>
                        <div class="column cost-box" v-html="cost(cover['gold_limit'])"></div>
                        <div class="column cost-box" v-html="cost(cover['silver_limit'])"></div>
                    </div>

                    <!-- Cruise -->
                    <div class="columns" v-for="cover in JSON.parse(cruiseCovers)" :key="cover.id">
                        <div class="column name-box" >
                            <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                                <span v-html="cover['covername']"></span>
                                <template v-slot:content>
                                    <div v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                                </template>
                            </b-tooltip>
                            <span v-else v-html="cover['covername']"></span>
                        </div>
                        <div class="column cost-box" v-html="cost(cover['cruise_limit'])"></div>
                    </div>

                    <div class="columns" v-for="cover in JSON.parse(covers)" :key="cover['coverName']">
                        <template v-if="!initialCoversIds.includes(cover['cover_limit_id']) && ((cover['longstay_limit'] !== 'N/A' && $route.query.type === 'LS') || $route.query.type !== 'LS')">
                            <div class="column name-box">
                                <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                                    <span v-html="cover['covername']"></span>
                                    <template v-slot:content>
                                        <div v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                                    </template>
                                </b-tooltip>
                                <span v-else v-html="cover['covername']"></span>
                            </div>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['platinum_limit'])"/>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['gold_limit'])"/>
                            <div v-if="$route.query.type !== 'LS' && $route.query.type !== 'CR'" class="column cost-box" v-html="cost(cover['silver_limit'])"/>
                            <div v-if="$route.query.type === 'LS'" class="column cost-box" v-html="cost(cover['longstay_limit'])"/>
                            <div v-if="$route.query.type === 'CR'" class="column cost-box" v-html="cost(cover['cruise_limit'])"/>
                        </template>    
                    </div>

                </div>
            </div>
            <br>

            <div class="covid-message" v-if="$route.query.type === 'CR'">
                *Option available to pay an additional premium to increase cancellation limit in increments of £1,000 up
                to a maximum of an overall cancellation limit of £10,000.
            </div>

            <div class="covid-message">
                Please Remember - There is no cover on any of our policies if you travel against the Foreign, Commonwealth and Development Office, government, local authority, or medical advice.
                Terms and Conditions Apply. Please see our <a href="https://covered2go.co.uk/policy-wordings-mul" target="_blank">Policy Wording</a> for full details.
            </div>

            <br>
            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
        </div>
    </transition>
</template>

<script>
    import InfoButtonOptions from "@/components/common/InfoButtonOptions";

    export default {
        name: "CompareCoverLevels",
        data() {
            return {
                covers: '{}',
                skiCovers: '{}',
                cruiseCovers: '{}',
                initialCovers: [],
                initialCoversIds: ['60', '1', '2', '3', '52', '53', '54'],
            }
        },
        methods: {
            cost(v) {
                let index;
                let colour = "red";

                if (v.includes('Not covered')) {
                    index = v.indexOf('Not covered');
                } else if (v.includes('Not included')) {
                    index = v.indexOf('Not included');
                } else if (v.includes('Included')) {
                    index = v.indexOf('Included');
                    colour = 'green';
                } else if (v.includes('Optional')) {
                    index = v.indexOf('Optional');
                    colour = 'black';
                }

                if (index >= 0) {
                    return `${v.substring(0, index)}<strong style="color: ${colour};">${v.substring(index, index + v.length)}</strong>`;
                } else {
                    return v;
                }
            }
        },
        components: {
            InfoButtonOptions
        },
        created() {

            const type = this.$route.query.type;
            this.$c2g.getCoverLimits('WHERE ski = 0 AND cruise = 0').then(res => {
                this.covers = res.data;

                // Initial covers
                const covers1 = JSON.parse(this.covers);
                this.initialCoversIds.forEach(Id => {
                    this.initialCovers.push(covers1.filter(c => c['cover_limit_id'] === Id)[0]);
                });

            });
            if(type === 'CR') {
                this.$c2g.getCoverLimits('WHERE cruise = 1').then(res => {
                    this.cruiseCovers = res.data;
                });
            }
            if(type === 'WS') {
                this.$c2g.getCoverLimits('WHERE ski = 1').then(res => {
                    this.skiCovers = res.data;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 40px 0 40px 0;
    }
    .title-box {
        background-color: $c2g_blue;
        color: white;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-left: 8px;
        font-weight: bold;
    }
    .cost-box {
        text-align: center;
        background-color: white;
        margin-left: 8px;
        border-bottom: 2px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }
    .top-cost-box {
        border-top-left-radius: 10px;
    }
    .name-box {
        background-color: white;
        border-bottom: 2px solid #ebebeb;
        font-weight: bold;
    }
    #background-container {
        background: rgb(249,145,49);
        background: linear-gradient(0deg, rgba(249,145,49,0.6138830532212884) 0%, rgba(249,145,49,0.3449754901960784) 18%, rgba(249,145,49,0) 100%);
    }
    /deep/ {
        .tooltip-content {
            min-width: 650px;
            text-align: left !important;
        }
    }
    .covid-message {
        background-color: #f9923167;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }
</style>